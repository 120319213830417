export const useSwipe = () => {

  const startX = ref(0);
  const endX = ref(0);

  const onTouchStart = (e: TouchEvent) => {
    startX.value = e.touches[0].clientX;
  };

  const onTouchMove = (e: TouchEvent) => {
    endX.value = e.touches[0].clientX;
  };

  const onTouchEnd = (callback: (direction: 'left' | 'right') => void) => {
    if (startX.value > endX.value) {
      callback('left');
    } else if (startX.value < endX.value) {
      callback('right');
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
}
